import { LayoutAuthHome } from "ui-organisms";
import Leftbar from "./Leftbar";
import { SolButton, SolDropdown, SolIcon } from "@solstice/sol-react";
import { Input, SelectAutoComplete, Toast } from "ui-atoms";
import { SearchOutlined } from "@material-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import debounce from "lodash.debounce";
import { getDecimalFormating, getFormatedDate } from "utils";
import { AddTagModal, EditTagModal, Loading, Table } from "ui-molecules";
import {
  METADATA_TABLE_COLUMNS,
  SEARCH_RESULT_LIMIT,
  SET_ALERT_MODAL,
  STATUS_ACTIVE,
  URLS,
} from "constant";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useApiCall } from "hooks";
import {
  deleteCityAPI,
  deleteCountryAPI,
  deleteIndustryAPI,
  deleteMarketAPI,
  deleteServiceAPI,
  deleteStateAPI,
  getCityAPI,
  getCountryAPI,
  getIndustryAPI,
  getMarketAPI,
  getServiceAPI,
  getStateAPI,
} from "services";
import { GlobalContext } from "context";
import { DirectionEnum } from "types";

const Metadata = () => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const { dispatch } = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [getIndustry] = useApiCall(getIndustryAPI);
  const [getService] = useApiCall(getServiceAPI);
  const [getCountry] = useApiCall(getCountryAPI);
  const [getState] = useApiCall(getStateAPI);
  const [getCity] = useApiCall(getCityAPI);
  const [getMarket] = useApiCall(getMarketAPI);
  const [deleteIndustry] = useApiCall(deleteIndustryAPI);
  const [deleteService] = useApiCall(deleteServiceAPI);
  const [deleteCountry] = useApiCall(deleteCountryAPI);
  const [deleteState] = useApiCall(deleteStateAPI);
  const [deleteCity] = useApiCall(deleteCityAPI);
  const [deleteMarket] = useApiCall(deleteMarketAPI);
  const [isEditTagModalOpen, setIsEditTagModalOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState<string>("");
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [sortFields, setSortFields] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState<any>({});

  useEffect(() => {
    if (
      !session?.is_superuser &&
      !session?.is_staff &&
      !session?.permissions?.groups?.includes("Global Admin")
    )
      navigate("/");
  }, [session]);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    setPage(1);
    if (!paramFilters) return;
    loadData();
  }, [paramFilters]);

  const loadData = () => {
    let API;
    switch (paramFilters?.tab) {
      case "service":
        API = getService;
        break;
      case "country":
        API = getCountry;
        break;
      case "state":
        API = getState;
        break;
      case "city":
        API = getCity;
        break;
      case "market":
        API = getMarket;
        break;
      default:
        API = getIndustry;
    }
    setIsFirstLoading(true);
    API({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
        // setFacets(res?.facets);
        setSortFields(res?.sort_fields);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      })
      .finally(() => {
        setIsFirstLoading(false);
      });
  };

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    let API;
    switch (paramFilters?.tab) {
      case "service":
        API = getService;
        break;
      case "country":
        API = getCountry;
        break;
      case "state":
        API = getState;
        break;
      case "city":
        API = getCity;
        break;
      case "market":
        API = getMarket;
        break;
      default:
        API = getIndustry;
    }
    setIsLoading(true);
    API({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData((prevData: any) => [...prevData, ...res?.docs]);
        setPage(res?.page);
        setTotal(res?.total);
        setSortFields(res?.sort_fields);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const handleDeleteTag = (item: any) => {
    const action = () => {
      try {
        let API;
        switch (paramFilters?.tab) {
          case "service":
            API = deleteService;
            break;
          case "country":
            API = deleteCountry;
            break;
          case "state":
            API = deleteState;
            break;
          case "city":
            API = deleteCity;
            break;
          case "market":
            API = deleteMarket;
            break;
          default:
            API = deleteIndustry;
        }
        API(item?.pk).then((res: any) => {
          if (!res?.status) return;
          loadData();
          Toast.success(`Successfully removed "${item?.name}"`);
        });
      } catch (err) {}
    };

    dispatch({
      type: SET_ALERT_MODAL,
      payload: {
        open: true,
        kind: "error",
        title: `Are you sure to remove "${item?.name}"?`,
        description: `Currently ${item?.count} user's are associated with this Industry`,
        icon: "warning",
        btn1: {
          label: "Cancel",
          onClick: () => {
            dispatch({
              type: SET_ALERT_MODAL,
              payload: {
                open: false,
                kind: "",
                title: "",
                description: "",
                icon: "",
                btn1: null,
                btn2: null,
              },
            });
          },
        },
        btn2: {
          label: "Confirm",
          onClick: () => {
            dispatch({
              type: SET_ALERT_MODAL,
              payload: {
                open: false,
                kind: "",
                title: "",
                description: "",
                icon: "",
                btn1: null,
                btn2: null,
              },
            });
            action();
          },
        },
      },
    });
  };

  const onClickSorting = (sort: string) => {
    const updatedFilters = {
      ...paramFilters,
      sort,
      direction:
        paramFilters?.direction === DirectionEnum.asc
          ? DirectionEnum?.desc
          : DirectionEnum.asc,
    };
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const handleFilter = (e: any, key: string) => {
    const updatedFilters = { ...paramFilters };
    if (!e?.length) {
      delete updatedFilters[key];
      setSelectedOptions((prev: any) => ({ ...prev, [key]: [] }));
    } else {
      const values = e?.map((item: any) => item?.value);
      updatedFilters[key] = values;
      setSelectedOptions((prev: any) => ({ ...prev, [key]: e }));
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const loadCountryOptions = async (keyword: string) => {
    return Promise.all([
      paramFilters?.country && getCountry({ ids: paramFilters?.country || [] }),
      getCountry({ keyword }),
    ]).then((res_list) => {
      const selected = (res_list?.[0]?.docs || [])?.map((country: any) => {
        return {
          value: country.pk,
          label: country?.name,
        };
      });
      setSelectedOptions((prev: any) => ({ ...prev, country: selected }));
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return combinedArray?.map((country: any) => {
        return {
          value: country.pk,
          label: country?.name,
        };
      });
    });
  };

  const loadStateOptions = async (keyword: string) => {
    return Promise.all([
      paramFilters?.state && getState({ ids: paramFilters?.state || [] }),
      getState({ keyword }),
    ]).then((res_list) => {
      const selected = (res_list?.[0]?.docs || [])?.map((state: any) => {
        return {
          value: state.pk,
          label: state?.name,
        };
      });
      setSelectedOptions((prev: any) => ({ ...prev, state: selected }));
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return combinedArray?.map((country: any) => {
        return {
          value: country.pk,
          label: country?.name,
        };
      });
    });
  };

  return (
    <>
      <LayoutAuthHome>
        <Leftbar />
        <div className="flex flex-col w-full h-full relative overflow-hidden">
          {/* Filter */}
          <div className="flex flex-row items-center pt-6 pb-8 mx-10 border-b border-b-jll-stroke-subdued justify-between flex-wrap">
            <div className="flex flex-row items-center space-x-3">
              {paramFilters &&
                (paramFilters?.tab === "state" ||
                  paramFilters?.tab === "city") && (
                  <SelectAutoComplete
                    placeholder="Country"
                    className="!mb-0 min-w-[200px] w-full"
                    label="Country"
                    size="sm"
                    isMulti
                    loadOptions={loadCountryOptions}
                    name="country"
                    value={selectedOptions?.country || []}
                    onChange={(e) => {
                      handleFilter(e, "country");
                    }}
                  />
                )}
              {paramFilters && paramFilters?.tab === "city" && (
                <SelectAutoComplete
                  placeholder="State"
                  className="!mb-0 min-w-[200px] w-full"
                  label="State"
                  size="sm"
                  isMulti
                  loadOptions={loadStateOptions}
                  name="state"
                  value={selectedOptions?.state || []}
                  onChange={(e) => {
                    handleFilter(e, "state");
                  }}
                />
              )}
              {(paramFilters?.tab === "state" ||
                paramFilters?.tab === "city") && (
                <div className="mt-8">
                  <SolButton
                    variant="tertiary"
                    className="whitespace-nowrap text-jll-text-base-subdued"
                    onSol-click={() => {
                      navigate(
                        `${URLS.METADATA.ROOT}${
                          paramFilters?.tab ? `?tab=${paramFilters?.tab}` : ""
                        }`
                      );
                      setSelectedOptions({});
                    }}
                  >
                    Clear filters
                  </SolButton>
                </div>
              )}
            </div>
            <div>
              <Input
                label="Search"
                leadingIcon={SearchOutlined}
                isClearable
                onChange={onChangeInput}
                value={keyword}
                className="w-[350px] !mb-0"
                placeholder="Search by name, country, city..."
              />
            </div>
          </div>

          <div className="flex flex-col mx-10 relative">
            <div className="flex flex-row items-center justify-between space-x-4 py-3 border-b border-b-jll-stroke-subdued">
              <span>{`${getDecimalFormating(total)} result${
                total > 1 ? "s" : ""
              }`}</span>
            </div>
          </div>

          <div className="flex flex-1 w-full h-full flex-col px-10 overflow-auto">
            {isFirstLoading ? (
              <Table.Loading avatar={false} />
            ) : (
              <>
                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      {METADATA_TABLE_COLUMNS?.map((column, idx) => (
                        <Table.Th
                          key={idx}
                          filterId={column.id}
                          sortFields={sortFields}
                          filters={paramFilters}
                          onClick={onClickSorting}
                        >
                          {column?.label}
                        </Table.Th>
                      ))}
                      <Table.Th>Action</Table.Th>
                    </Table.Tr>
                  </Table.Thead>
                  <Table.Tbody>
                    {data?.map((item: any, idx: number) => (
                      <Table.Tr key={idx}>
                        {METADATA_TABLE_COLUMNS?.map((column, idx1) => (
                          <Table.Td key={idx1} className="!whitespace-normal">
                            {/* <p className="line-clamp-2">{item?.[column?.id]}</p> */}
                            {(() => {
                              switch (column?.id) {
                                case "create_timestamp":
                                case "update_timestamp":
                                  return getFormatedDate(item?.[column?.id]);

                                case "updated_user":
                                  return [
                                    item?.first_name ||
                                      item?.updated_first_name,
                                    item?.last_name || item?.updated_last_name,
                                  ]?.join(" ");

                                case "count":
                                  return item?.count || item?.user_count;

                                case "name":
                                  return (
                                    <Table.Td.Clamp
                                      id={`tag_${item?.document_type}_${item?.pk}`}
                                    >
                                      {item?.[column?.id]}
                                    </Table.Td.Clamp>
                                  );

                                default:
                                  return (
                                    <p className="line-clamp-2">
                                      {item?.[column?.id]}
                                    </p>
                                  );
                              }
                            })()}
                          </Table.Td>
                        ))}
                        <Table.Td className="space-x-3">
                          <SolButton
                            variant="secondary"
                            onSol-click={() => {
                              setSelectedItem(item);
                              setIsEditTagModalOpen(true);
                            }}
                          >
                            Edit <SolIcon icon="edit" />
                          </SolButton>
                          <SolButton
                            variant="secondary"
                            onSol-click={() => {
                              handleDeleteTag(item);
                            }}
                          >
                            Delete <SolIcon icon="delete" />
                          </SolButton>
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                </Table>
                {!!(total > data?.length) && (
                  <div
                    className="w-full py-2 flex justify-center items-center"
                    ref={sentryRef}
                  >
                    <Loading />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </LayoutAuthHome>

      <AddTagModal kind={paramFilters?.tab || "industry"} loadData={loadData} />
      <EditTagModal
        isOpen={isEditTagModalOpen}
        setIsOpen={setIsEditTagModalOpen}
        kind={paramFilters?.tab || "industry"}
        selectedItem={selectedItem}
        loadData={loadData}
      />
    </>
  );
};

export default Metadata;
