import { SearchOutlined } from "@material-ui/icons";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { SEARCH_RESULT_LIMIT, SET_ALERT_MODAL, STATUS_ACTIVE } from "constant";
import { GlobalContext } from "context";
import { useApiCall } from "hooks";
import debounce from "lodash.debounce";
import { useContext, useEffect, useMemo, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  getBrokerListAPI,
  getCountryAPI,
  getMarketAPI,
  patchProfileAPI,
} from "services";
import { BrokerProps, ProfileProps } from "types";
import { Input, SelectAutoComplete, Toast } from "ui-atoms";
import {
  Loading,
  Modal,
  StyledListValue,
  StyledListValueProps,
  Table,
} from "ui-molecules";
import { getDecimalFormating, isArrayContained } from "utils";

interface LeaderModalProps {
  isOpen: boolean;
  setIsOpen: any;
  profile: ProfileProps | null;
  setProfile?: any;
}

const LEADER_TABLE_COLUMNS: {
  id: StyledListValueProps["valueKey"];
  label: string;
  sort?: string;
}[] = [
  {
    id: "name",
    label: "Name",
  },
  // {
  //   id: "leadership",
  //   label: "Leadership",
  // },
  {
    id: "job_title",
    label: "Job Title",
  },
  {
    id: "country",
    label: "Country",
  },
];

const LeaderModal: React.FC<LeaderModalProps> = ({
  isOpen,
  setIsOpen,
  profile,
  setProfile,
}) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { meta } = state;
  const [getBrokerList, isLoading] = useApiCall(getBrokerListAPI);
  const [getCountry] = useApiCall(getCountryAPI);
  const [getMarket] = useApiCall(getMarketAPI);
  const [patchProfile] = useApiCall(patchProfileAPI);
  const [data, setData] = useState<BrokerProps[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState<string>("");
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [selectedOptions, setSelectedOptions] = useState<any>({});

  useEffect(() => {
    if (!isOpen) return;
    setPage(1);
    setIsFirstLoading(true);
    getBrokerList({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      })
      ?.finally(() => {
        setIsFirstLoading(false);
      });
  }, [paramFilters, isOpen]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getBrokerList({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      ...paramFilters,
    }).then((res: any) => {
      if (!res) return;
      setData((prevData: any) => [...prevData, ...res?.docs]);
      setPage(res?.page);
      setTotal(res?.total);
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  const updateKeywordFilter = (e: any) => {
    // setFilters({ keyword: e.target.value });
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    setParamFilters(updatedFilters);
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 500),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const loadCountryOptions = async (keyword: string) => {
    return Promise.all([
      paramFilters?.country && getCountry({ ids: paramFilters?.country || [] }),
      getCountry({ keyword, region: paramFilters?.["region"] || [] }),
    ]).then((res_list) => {
      const selected = (res_list?.[0]?.docs || [])?.map((country: any) => {
        return {
          value: country.pk,
          label: country?.name,
        };
      });
      setSelectedOptions((prev: any) => ({ ...prev, country: selected }));
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...(res_list?.[1]?.docs || []).filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return combinedArray?.map((country: any) => {
        return {
          value: country.pk,
          label: country?.name,
        };
      });
    });
  };

  const loadMarketOptions = async (keyword: string) => {
    return Promise.all([
      paramFilters?.market && getMarket({ ids: paramFilters?.market || [] }),
      getMarket({
        keyword,
        region: paramFilters?.["region"] || [],
        country: paramFilters?.["country"] || [],
      }),
    ]).then((res_list) => {
      const selected = (res_list?.[0]?.docs || [])?.map((market: any) => {
        return {
          value: market.pk,
          label: market?.name,
        };
      });
      setSelectedOptions((prev: any) => ({ ...prev, market: selected }));
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return combinedArray?.map((market: any) => {
        return {
          value: market.pk,
          label: market?.name,
        };
      });
    });
  };

  const handleFilter = (e: any, key: string) => {
    const updatedFilters = { ...paramFilters };
    if (!e?.length) {
      delete updatedFilters[key];
      setSelectedOptions((prev: any) => ({ ...prev, [key]: [] }));
    } else {
      const values = e?.map((item: any) => item?.value);
      updatedFilters[key] = values;
      setSelectedOptions((prev: any) => ({ ...prev, [key]: e }));
    }
    setParamFilters(updatedFilters);
  };

  const handleUpdate = (
    item: BrokerProps,
    option: "add" | "delete" = "add"
  ) => {
    try {
      const action = () => {
        try {
          let original = [...(profile?.related_profiles || [])];
          if (option === "add") {
            original = Array.from(
              new Set([...original, ...(item?.broker_profile_ids || [])])
            );
          } else {
            original = [...original]?.filter(
              (p_pk: number) => !item?.broker_profile_ids?.includes(p_pk)
            );
          }
          patchProfile({
            pk: profile?.id,
            related_profiles: original,
          }).then((res: any) => {
            if (!res) return;
            setProfile({
              ...profile,
              related_profiles: res?.related_profiles,
            });
            Toast.success(
              `"${[item?.first_name, item?.last_name]?.join(
                " "
              )}"'s profile successfully ${
                option === "add" ? "added to" : "removed from"
              } Leadership`
            );
          });
        } catch (err) {}
      };

      dispatch({
        type: SET_ALERT_MODAL,
        payload: {
          open: true,
          kind: "error",
          title: `${option === "add" ? "Add to" : "Remove from"} leadership`,
          description: `Are you sure you want to ${
            option === "add" ? "add" : "remove"
          } "${[item?.first_name, item?.last_name]?.join(" ")}" ${
            option === "add" ? "to" : "from"
          } leadership?`,
          icon: "warning",
          btn1: {
            label: "No",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                },
              });
            },
          },
          btn2: {
            label: "Yes",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                },
              });
              action();
            },
          },
        },
      });
    } catch (err) {}
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="extra">
      <Modal.Header>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h2 className="text-xl">Add a leader to the list</h2>
            <p>
              Bibendum ullamcorper quis nec nunc. Morbi consectetur, quam non.
            </p>
          </div>
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body className="!overflow-hidden flex flex-col">
        <div className="mt-6 flex flex-row items-center space-x-5">
          <div className="w-full">
            <Input
              label="Search"
              leadingIcon={SearchOutlined}
              isClearable
              className="!mb-0"
              value={keyword}
              onChange={onChangeInput}
              placeholder="Search by name, country, city..."
            />
          </div>
          <SelectAutoComplete
            placeholder="Country"
            className="!mb-0 min-w-[200px]"
            label="Country"
            size="sm"
            isMulti
            loadOptions={loadCountryOptions}
            name="country"
            value={selectedOptions?.country || []}
            onChange={(e) => {
              handleFilter(e, "country");
            }}
            key={[...(paramFilters?.["region"] || [])]?.toString()}
          />

          <SelectAutoComplete
            placeholder="Market"
            className="!mb-0 min-w-[200px]"
            label="Market"
            size="sm"
            isMulti
            loadOptions={loadMarketOptions}
            name="market"
            value={selectedOptions?.market || []}
            onChange={(e) => {
              handleFilter(e, "market");
            }}
            key={[
              ...(paramFilters?.["region"] || []),
              ...(paramFilters?.["country"] || [100]),
            ]?.toString()}
          />
          <SelectAutoComplete
            name="leadership"
            label="Leadership"
            size="sm"
            isMulti
            className="!mb-0 min-w-[200px]"
            options={Object.keys(meta?.broker?.leadership || [])?.map(
              (key: number | string) => ({
                value: Number(key),
                label: meta?.broker?.leadership?.[key]?.value,
              })
            )}
            value={selectedOptions?.leadership || []}
            onChange={(e) => {
              handleFilter(e, "leadership");
            }}
          />
        </div>
        <div className="flex flex-col relative">
          <div className="flex flex-row items-center justify-between space-x-4 py-3 border-b border-b-jll-stroke-subdued">
            <span>{`${getDecimalFormating(total)} result${
              total > 1 ? "s" : ""
            }`}</span>
          </div>
        </div>
        <div className="h-full overflow-y-auto">
          {isFirstLoading ? (
            <Table.Loading size={4} />
          ) : (
            <>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    {LEADER_TABLE_COLUMNS.map((column, idx) => (
                      <Table.Th key={idx}>{column.label}</Table.Th>
                    ))}
                    <Table.Th>Action</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {data?.map((item: any, idx: number) => (
                    <Table.Tr key={idx}>
                      {LEADER_TABLE_COLUMNS?.map((column, idx1) => (
                        <Table.Td key={idx1} className="!whitespace-normal">
                          <StyledListValue broker={item} valueKey={column.id} />
                        </Table.Td>
                      ))}
                      <Table.Td>
                        {!!item?.broker_profile_ids?.length && !!isArrayContained(item?.broker_profile_ids, profile?.related_profiles || []) ? (
                          <SolButton
                            variant="secondary"
                            onSol-click={() => handleUpdate(item, "delete")}
                            className="sol-w-full"
                          >
                            Remove
                            <SolIcon icon="delete" />
                          </SolButton>
                        ) : (
                          <SolButton
                            variant="secondary"
                            onSol-click={() => handleUpdate(item)}
                            className="sol-w-full"
                          >
                            Add
                            <SolIcon icon="add" />
                          </SolButton>
                        )}
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
              {!!(total > data?.length) && (
                <div
                  className="w-full py-2 flex justify-center items-center"
                  ref={sentryRef}
                >
                  <Loading />
                </div>
              )}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LeaderModal;
