import { Dropzone } from "ui-atoms";

const ProfilePhoto = () => {
  return (
    <>
      <div className="flex flex-col w-full h-full relative overflow-hidden">
        <div className="flex flex-col pt-8 mx-10 mb-12">
          <h3 className="text-xl mb-2">Profile image</h3>
          <p className="text-sm text-jll-text-base-subdued">
            Curabitur a ipsum eget dolor malesuada tristique
          </p>
        </div>
        <div className="flex flex-col mx-10 relative">
          <div className="grid grid-cols-2 gap-10 mb-12">
            <div className="col-span-1">
              <Dropzone
                setDropFiles={
                  (files: any) => {}
                  // handleSubmitFile("images", files)
                }
                acceptType="image/*"
                className="h-full"
              />
            </div>
            <div className="col-span-1">
              <Dropzone
                title="You will be redirected to Aragon, please follow the process"
                isOR={false}
                btnLabel="Click for AI generated photo"
                className="h-full"
                setDropFiles={
                  (files: any) => {}
                  // handleSubmitFile("images", files)
                }
                acceptType="image/*"
              />
            </div>
          </div>

          <div>
            <h3 className="text-xl mb-2">Profile photo - AI generated</h3>
            <p className="text-sm text-jll-text-base-subdued">
              Curabitur a ipsum eget dolor malesuada tristique
            </p>
            <div>
                
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePhoto;
