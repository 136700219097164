import { ArrowBackOutlined } from "@material-ui/icons";
import {
  SolAvatar,
  SolBadge,
  SolButton,
  SolIcon,
  SolStatusIndicator,
} from "@solstice/sol-react";
import {
  SolStatusIndicatorSizeEnum,
  SolStatusIndicatorStatusEnum,
} from "@solstice/sol-stencil";
import { PUBLISH_STATUS_PUBLISHED, STATUS_ACTIVE, URLS } from "constant";
import { useApiCall } from "hooks";
import { useContext, useRef } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { patchBrokerAPI, postImageAPI } from "services";
import { BrokerProps, ProfileProps } from "types";
import { capitalizeName, getFormatedDateTime } from "utils";
import cn from "classnames";
import { LoadingPage } from "ui-molecules";

interface IDetailLeftSide {
  broker?: BrokerProps | null;
  setBroker?: any;
  isLoading?: boolean;
  isLog?: boolean;
}

const DetailLeftSide: React.FC<IDetailLeftSide> = ({
  broker,
  setBroker,
  isLoading,
  isLog,
}) => {
  const session = JSON.parse(sessionStorage.getItem("session") || "null");
  const navigate = useNavigate();
  const { brokerId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const fileInputRef = useRef<any>(null);
  const [patchBroker] = useApiCall(patchBrokerAPI);
  const isFullPermission =
    !!session?.permissions?.groups?.length ||
    !!session?.is_superuser ||
    !!session?.is_staff;

  const handleButtonClick = () => {
    // fileInputRef.current.click();
    navigate(`/profile/${brokerId}/photo`)
  };

  // const handleFileChange = async (event: any) => {
  //   const file = event?.target?.files[0];
  //   if (file) {
  //     try {
  //       const result = await postImageAPI(file);
  //       patchBroker({ pk: broker?.pk || broker?.id, photos: [result] }).then(
  //         (res: BrokerProps) => {
  //           if (!res) return;
  //           setBroker(res);
  //         }
  //       );
  //     } catch (err) {}
  //   }
  // };

  return (
    <div className="min-w-[280px] h-full bg-jll-surface-base-secondary relative">
      {isFullPermission && (
        <div className="h-[68px] flex flex-row items-center pl-6 space-x-3 border-b border-b-jll-light-stroke-default">
          <button
            onClick={() => (isLog ? navigate(-1) : navigate(URLS.PROFILE.ROOT))}
            className="flex flex-row items-center"
          >
            <ArrowBackOutlined className="!w-5 !h-5 mr-2" />
            {isLog ? "Back to profile" : "Back"}
          </button>
        </div>
      )}

      {isLoading ? (
        <LoadingPage mode="back" />
      ) : (
        <>
          <div
            className={cn(
              "absolute top-0 bottom-0  py-8 space-y-8 overflow-y-auto w-full",
              {
                "mt-[68px]": isFullPermission,
              }
            )}
          >
            <div className="border-b border-b-jll-light-stroke-default px-10 pb-8">
              <div className="flex flex-row items-center space-x-4 mb-4">
                <SolAvatar
                  name={capitalizeName(
                    [broker?.first_name, broker?.last_name].join(" ")
                  )}
                  size="56"
                  image={
                    !!broker?.photos?.length
                      ? (broker?.photos?.[0] as string)
                      : ""
                  }
                />
                <div>
                  <p className="text-lg">
                    {[broker?.first_name, broker?.last_name]?.join(" ") || ""}
                  </p>
                  <p className="text-jll-text-base-subdued">
                    {broker?.job_title || ""}
                  </p>
                </div>
              </div>
              {!isLog && (
                <>
                  <div className="mb-8">
                    <SolButton
                      variant="outlined"
                      className="sol-w-full sol-bg-transparent"
                      onSol-click={handleButtonClick}
                    >
                      <SolIcon icon="image" />
                      Change profile image
                    </SolButton>
                    {/* <input
                      type="file"
                      ref={fileInputRef}
                      className="hidden"
                      accept="image/*"
                      onChange={handleFileChange} // Handle file selection
                    /> */}
                  </div>

                  <div className="space-y-8">
                    {broker?.profiles
                      ?.filter(
                        (profile: ProfileProps) =>
                          profile?.active_status === STATUS_ACTIVE
                      )
                      ?.map((profile: ProfileProps, idx) => {
                        let local_status = 0; // 0 - No Text, 1: Match, 2: Not Match

                        if (
                          profile?.publish_status ===
                            PUBLISH_STATUS_PUBLISHED &&
                          !profile?.dirty
                        ) {
                          local_status = 1;
                        } else if (
                          profile?.publish_status ===
                            PUBLISH_STATUS_PUBLISHED &&
                          profile?.dirty
                        ) {
                          local_status = 2;
                        }
                        return (
                          <div className="space-y-2" key={idx}>
                            <SolButton
                              variant="outlined"
                              className={cn("sol-w-full", {
                                "sol-bg-hover":
                                  searchParams?.get("tab") ===
                                  profile?.id?.toString(),
                                "sol-bg-transparent":
                                  searchParams?.get("tab") !==
                                  profile?.id?.toString(),
                              })}
                              onSol-click={() => {
                                searchParams.set(
                                  "tab",
                                  profile?.id?.toString()
                                );
                                if (
                                  location?.pathname
                                    ?.toLowerCase()
                                    ?.includes("edit")
                                ) {
                                  searchParams.set("sub", "bio");
                                }
                                setSearchParams(searchParams);
                              }}
                            >
                              {profile?.country?.name} ({profile?.language_name}
                              )
                              <SolIcon icon="arrow_right_alt" />
                            </SolButton>
                            <div className="flex flex-row items-center space-x-2">
                              <span className="text-sm text-jll-text-base-subdued">
                                Status
                              </span>
                              <SolIcon
                                icon="error"
                                size="20"
                                className="text-jll-icon-base-subdued"
                              />
                            </div>
                            <div className="flex flex-row space-x-2 items-center">
                              <SolStatusIndicator
                                size={"16" as SolStatusIndicatorSizeEnum}
                                status={
                                  (profile?.publish_status ===
                                  PUBLISH_STATUS_PUBLISHED
                                    ? "available"
                                    : "busy") as SolStatusIndicatorStatusEnum
                                }
                                online
                              />
                              <span className="text-sm">
                                {profile?.publish_status ===
                                PUBLISH_STATUS_PUBLISHED
                                  ? "Published"
                                  : "Unpublished"}
                              </span>
                            </div>
                            {local_status !== 0 && (
                              <div className="flex flex-row space-x-1.5 items-center">
                                <SolIcon
                                  icon="check_circle"
                                  size="20"
                                  className={cn("-ml-0.5", {
                                    "text-jll-icon-rag-success-1":
                                      local_status === 1,
                                    "text-jll-surface-rag-warning-1":
                                      local_status === 2,
                                  })}
                                />
                                <span className="text-sm">
                                  {local_status === 1
                                    ? "Local data matches"
                                    : "Local data does not match"}
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </>
              )}

              <div className="space-y-4 flex flex-col">
                {/* {broker?.profiles?.map((item: any) => (
              <SolButton
                variant="secondary"
                className="sol-w-full"
                onSol-click={() => {
                  searchParams.set("tab", item?.country?.id);
                  if (location?.pathname?.toLowerCase()?.includes("edit")) {
                    searchParams.set("sub", "license");
                  }
                  setSearchParams(searchParams);
                }}
              >
                {item?.country?.name} <SolIcon icon="arrow_right_alt" />
              </SolButton>
            ))} */}
              </div>
            </div>
            <div className="px-10">
              <div className="mb-10">
                <SolBadge
                  variant="filled"
                  icon="save"
                  state="success"
                  className="mb-3"
                >
                  <span>Auto-saved</span>
                </SolBadge>
                <p className="text-jll-text-base-subdued text-sm">
                  {getFormatedDateTime(broker?.update_timestamp)}
                </p>
              </div>

              <p className="text-sm mb-5">History</p>
              <div className="mb-10">
                <p className="text-jll-text-base-subdued text-sm mb-1">
                  Modified by
                </p>
                <p className="text-sm mb-1">
                  {[
                    broker?.updated_user_first_name,
                    broker?.updated_user_last_name,
                  ]?.join(" ") || broker?.updated_user_email}
                </p>
                <p className="text-jll-text-base-subdued text-sm mb-1">
                  Modified on
                </p>
                <p className="text-sm mb-1">
                  {getFormatedDateTime(broker?.update_timestamp)}
                </p>
              </div>
              <div className="mb-10">
                <p className="text-jll-text-base-subdued text-sm mb-1">
                  Created on
                </p>
                <p className="text-sm mb-1">
                  {getFormatedDateTime(broker?.create_timestamp)}
                </p>
              </div>

              {!isLog && (
                <SolButton
                  variant="secondary"
                  onSol-click={() =>
                    navigate(`${URLS.PROFILE.ROOT}/${broker?.id}/log`)
                  }
                >
                  <SolIcon icon="history" />
                  View activity log
                  <SolIcon icon="arrow_right_alt" />
                </SolButton>
              )}
            </div>
          </div>
        </>
      )}

      {/* <div className="absolute bottom-0 left-0 right-0 h-[116px] py-10 px-10">
        <SolButton variant="secondary">
          <SolIcon icon="call" />
          Support
        </SolButton>
      </div> */}
    </div>
  );
};

export default DetailLeftSide;
